<template>
  <div>
    <div v-show="editable&&!disabled" class="col float-right">
      <q-btn-group outline>
        <q-chip
          class="no-border"
          style="margin-right:5px !important" 
          square outline
          color="red" 
          text-color="white" 
          icon="report" 
          size="12px" >
          진행항목이 전부&nbsp;<em>'완료'</em>&nbsp; 되었을 경우 &nbsp;<strong>활성화</strong> 됩니다.
        </q-chip>
        <c-btn 
          :disabled="!checkComplete"
          label="완료" 
          icon="check"
          @btnClicked="() => { completeDialog = true }" />
        <c-btn 
          v-show="false"
          :isSubmit="isComplete"
          :url="completeUrl"
          :param="moc"
          mappingType="PUT"
          label="완료" 
          icon="check"
          @beforeAction="completeMoc"
          @btnCallback="completeMocCallback" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :moc.sync="moc"
          :relatedWork.sync="tab.relatedWork"
          :disabled.sync="disabled"
          @saveCallback="saveCallback"
        />
      </template>
    </c-tab>
    <q-dialog v-model="completeDialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">변경완료의견</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input type="textarea" dense v-model="moc.mocCompleteOpinion" autofocus />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="완료" @click.prevent="checkCompleteValid" />
          <q-btn flat label="취소" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'moc-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopMocId: '',
        mocTypeCd: '',
        mocStepCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      splitter: 5,
      tab: '',
      tabItems: [],
      moc: {
        sopMocId: '',  // MOC 일련번호
        mocNo: '',  // moc 관리번호
        plantCd: '',  // 사업장 코드
        initiativeUserId: '',  // 발의자 ID
        initiativeUserName: '',  // 발의자 명
        initiativeDeptCd: '',  // 발의 부서 코드
        initiativeDeptName: '',  // 발의 부서명
        mocTitle: '',  // 변경관리 제목
        mocSummary: '',  // 변경 개요
        mocTypeCd: 'MT00000001',  // 변경구분_공통코드(정상/비상/임시)
        mocWriteLevelCd: null,  // 변경등급_작성
        mocConfirmLevelCd: null,  // 변경등급_검토
        processSatefySecureMeasures: '',  // 공정안전 확보 대책
        technicalBasisProcessDesign: '',  // 변경계획에 대한 공정 및 설계의 기술근거
        safetyReliabilityImprovementEffect: '',  // 안전성에 필요한 사항 및 신뢰성 향상 효과
        leaderOpinion: '',  // 팀장의견
        relatedLaws: '',  // 관련 볍령_텍스트
        processRiskAssessFlag: '',  // 공정위험성 평가 여부
        jobRiskAssessFlag: '',  // 작업위헝성 평가 여부
        mocPeriod: [],
        mocStartDate: '',  // 변경 시작일
        mocEndDate: '',  // 변경 종료일
        mocStepCd: '',  // 변경관리 단계
        mocActionDeptCd: '',  // 변경실행 부서코드
        mocActionResponsibilityUserId: '',  // 변경실행 책임자 ID
        mocCompleteCheckUserId: '',  // 변경완료 확인자 ID
        mocCompleteCheckDeptCd: '',  // 변경완료 확인 부서 코드
        mocCompleteOpinion: '',  // 변경완료 의견
        mocBenefitCds: '',  // 변경관리 기대효과_복수
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checklists: [],
        checklistEquips: [],
        checklistDrives: [],
        equipments: [],
        reviews: [],
        committees: [],
        committeeReviewItems: [],
        committeeHold: {
          sopMocId: '',  // MOC 일련번호
          firstHoldDate: '',  // (최초) 개최일
          firstHoldArea: '',  // (최초) 개최장소
          firstReviewContents: '',  // (최초) 검토내용
          firstReviewResult: '',  // (최초) 검토결과
          professionalHoldDate: '',  // (전문가) 개최일
          professionalHoldArea: '',  // (전문가) 개최장소
          professionalReviewContents: '',  // (전문가) 검토내용
          professionalReviewResult: '',  // (전문가) 검토결과
          finalHoldDate: '',  // (최종) 개최일
          finalHoldArea: '',  // (최종) 개최장소
          finalReviewContents: '',  // (최종) 검토내용
          finalReviewResult: '',  // (최종) 검토결과
          changeApprovalFlag: '',  // 변경 승인 여부
          changeApprovalReason: '',  // 변경 승인 사유
          changeApprovalDate: '',  // 승인일자
          changeKeepFlag: '',  // 변경유지 여부
          changeKeepReason: '',  // 변경유지 사유
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
        },
        relatedWorks: [],
        prestartupImproves: [],
      },
      editable: true,
      detailUrl: '',
      completeUrl: '',
      isComplete: false,
      completeDialog: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      return numHeight + 'px';
    },
    disabled() {
      // 비상변경에 대해서는 생각하지 않음
      let stepCheck = this.param.mocTypeCd === 'MT00000001' ? 'MS00000015' : this.param.mocTypeCd === 'MT00000005' ? 'MT00000020' : 'ME00000010'
      return this.param.sopMocId && this.param.mocStepCd !== stepCheck
    },
    checkComplete() {
      // 선정한 진행항목들이 전부 완료되었을 경우 사용자가 완료처리할 수 있도록 체크
      let returnData = true
      if (this.moc.relatedWorks && this.moc.relatedWorks.length > 0) {
        this.$_.forEach(this.moc.relatedWorks, relatedWork => {
          if (relatedWork.completeFlag !== 'Y') {
            returnData = false;
            return false;
          }
        })
      }
      return returnData;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.moc.change.action.get.url
      this.completeUrl = transactionConfig.sop.moc.change.complete.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.param.sopMocId) {
        this.$http.url = this.$format(this.detailUrl, this.param.sopMocId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.moc, _result.data);
          this.tabItems = [];
          if (this.moc.relatedWorks && this.moc.relatedWorks.length > 0) {
            this.tab = this.moc.relatedWorks[0].mocRelatedTaskCd
            this.$_.forEach(this.moc.relatedWorks, item => {
              this.tabItems.push({
                name: item.mocRelatedTaskCd, icon: item.mocRelatedTaskIcon, 
                label: this.$comm.convertHtml(item.mocRelatedTaskNameTab), 
                component: () => import(`${'./taskProceeding.vue'}`),
                relatedWork: item, key: uid(),
              })
            })
          }
        },);
      }
    },
    saveCallback(data) {
      this.param.sopMocId = data;
      this.$emit('emitStep', {
        name: 'keySetting',
        param: data
      })
      this.getDetail();
    },
    checkCompleteValid() {
      if (!this.moc.mocCompleteOpinion) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '변경완료의견을 입력하세요.', // 변경완료의견을 입력하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.completeDialog = false;
        this.completeMoc();
      }
    },
    completeMoc() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '변경완료하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.moc.chgUserId = this.$store.getters.user.userId
          this.moc.mocStepCd = (this.param.mocTypeCd === 'MT00000001' ? 'MS00000020' : this.param.mocTypeCd === 'MT00000005' ? 'MT00000025' : 'ME00000015')

          this.isComplete = !this.isComplete
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    completeMocCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      // 계획 상세 조회
      this.getDetail();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: this.moc.mocStepCd
      })
    },
  }
};
</script>